.carousel-inner{
  background-color: #333;
}

.carousel-fade{
  .carousel-inner{
    .item{
      opacity: 0;
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      -o-transition-property: opacity;
      transition-property: opacity;
    }
    .active{
      opacity: 1;
    }
    .active.left,
    .active.right{
      left: 0;
      opacity: 0;
      z-index: 1;
    }
    .next.left,
    .prev.right{
      opacity: 1;
    }
  }
  .carousel-control{
    z-index: 2;
  }
}

.carousel-indicators {
  li {
    border: 2px solid @carousel-indicator-border-color;
  }
}

.carousel-indicators{
  &.indicators-top{
    position: absolute;
    top: 10px;
    right: 15px;
    bottom: initial;
    left: initial;
    margin: 0;
    list-style: none;
    width: initial;
    max-height: 40px;
    z-index: 5;
  }
}

.owl-carousel .items{
  margin: 0 5px;
  > img{
    display: block;
    width: 100%;
    height: auto;
  }
}