.form-control {
  display: block;
  width: 100%;
  height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: (@padding-base-vertical + 4) @padding-base-horizontal;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @input-color;
  background-color: @input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 2px solid @input-border;
  border-radius: @input-border-radius;
  // .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

  // Customize the `:focus` state to imitate native WebKit styles.
  .form-control-focus();

  // Placeholder
  .placeholder();

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    background-color: @input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content
  }

  &[readonly]{
    &:focus,
    &:active{
      border: 2px solid #e6e6e6;
      outline: none;
      .box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
    }
  }

  // Reset height for `textarea`s
  textarea& {
    height: auto;
    resize: vertical;
  }
}

.form-group {
  margin-bottom: 15px;
}

.help-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: lighten(@text-color, 50%); // lighten the text some for contrast
}

.form-inline {
  .radio,
  .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    vertical-align: middle;
    position: relative;
    top: -3px;
    label {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
}

.kd-upload {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 4px 10px;
  color: #ffffff;
  background-color: @gray;
  text-decoration: none;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-bottom: 1px solid darken(@gray, 20%);
  line-height: @line-height-base;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  input[type="text"]{
    display: none;
    color: #666666;
    background-color: #EEEEEE;
    padding: 0 5px;
    border: 0px;
    width: 145px;
    text-align: right;
    margin-left: 10px;
  }
  input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 1em;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.checkbox label{
  min-height: 17px; 
}

.radio label{
  min-height: 15px;
}

.input-group-addon{
  .kd-checkbox-label{
    margin: 0;
    padding-left: 18px !important;
    height: 17px;
  }
  .kd-radio-label{
    margin: 0;
    padding-left: 15px !important;
  }
}

input[type=checkbox].kd-checkbox {
  display:none;
}
input[type=checkbox].kd-checkbox + label.kd-checkbox-label {
  padding-left: 26px;
  height: 17px;
  display: inline-block;
  line-height: 17px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 1em;
  color: @gray;
  vertical-align: middle;
  cursor: pointer;
}
input[type=checkbox].kd-checkbox:checked + label.kd-checkbox-label {
  background-position: 0 -17px;
}
label.kd-checkbox-label {
  background-image:url(http://i.imgur.com/BUph2de.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type=radio].kd-radio {
  display:none;
}
input[type=radio].kd-radio + label.kd-radio-label {
  display: inline-block;
  padding-left: 25px;
  height: 15px;
  background-position: 0 0;
  background-repeat: no-repeat;
  vertical-align: middle;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}
input[type=radio].kd-radio:checked + label.kd-radio-label {
  background-position: 0 -15px;
}
label.kd-radio-label {
  background-image:url(http://i.imgur.com/gNLyFgE.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropzone{
  min-height: 50px;
  height: auto;
  background-color: @gray-lightest;
  border: 2px dashed darken(@gray-lightest, 10%);
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &.dz-started .dz-message{
    display: none;
    .opacity(0);
  }
  .dz-message{
    display: block;
    text-align: center;
    padding: 1em 0;
    margin: auto;
    .fa{
      font-size: 3em;
      margin-bottom: 10px;
    }
  }
  //preview template
  .dz-preview{
    width: 100px;
    display: inline-block;
    margin-right: 10px;
    &.dz-error{
      .dz-success-mark{ display: none; }
    }
    &.dz-succes{
      .dz-error-mark{ display: none; }
    }
    .dz-details{
      display: inline-block;
      position: relative;
      width: 100px;
      padding: 5px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: #FFFFFF;
      .box-shadow(0 0 3px rgba(0,0,0,.25));
      img{
        display: block;
        max-width: 100%;
        height: auto;
      }
      .dz-filename{
        display: none;
      }
      .dz-size{
        text-align: center;
        font-size: 0.8em;
        color: @gray;
        margin-top: 5px;
      }
      .dz-progress{
        width: 100%;
        height: 3px;
        background: darken(@gray-lighter, 10%);
      }
      .dz-success-mark,
      .dz-error-mark{
        position: absolute;
        top: 0;
        right: 0;
        padding: 0px 5px;
        background: #FFF;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
      }
      .dz-success-mark{
        span{ color: @theme-green; }
      }
      .dz-error-mark{
        span{ color: @theme-red; }
      }
    }
    .dz-error-message{
      width: 100%;
      font-size: 0.7em;
      color: @theme-red;
      text-align: center;
      margin: 10px auto;
      line-height: 1.2em;
    }
    .dz-mremove{
      font-size: 0.9em;
    }
  }
}



