.tooltip-inner {
	border-radius: 2px;
  border: 2px solid #222;
  white-space: nowrap
}

.tooltip {
	&.top .tooltip-arrow {
	  border-top-color: #222;
	}
	&.top-right .tooltip-arrow {
	  border-top-color: #222;
	}
	&.right .tooltip-arrow {
	  border-right-color: #222;
	}
	&.left .tooltip-arrow {
	  border-left-color: #222;
	}
	&.bottom .tooltip-arrow {
	  border-bottom-color: #222;
	}
	&.bottom-left .tooltip-arrow {
	  border-bottom-color: #222;
	}
	&.bottom-right .tooltip-arrow {
	  border-bottom-color: #222;
	}
}