.window{
  clear: both;
  border: 2px solid #e6e6e6;
  border-width: 0 2px 4px 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 20px;
  .btn{
    padding: 2px 12px;
    color: #FFF;
  }
  .btn-sm{
    padding: 2px 6px;
    margin-top: 3px;
  }
  .btn-xs{
    padding: 1px 4px;
    margin-top: 5px;
    font-size: 80%;
  }
  > a{
    color: #333;
    text-decoration: none;
  }
  > .window-title{
    background-color: #e6e6e6;
    padding: 8px 10px;
    border-bottom: 2px solid darken(#e6e6e6, 3%);
    color: #333;
    &:before,
    &:after{
      display: table;
      content: "";
    }
    &:after{
      clear: both;
    }
    > .window-heading{
      margin: 0;
      padding: 0;
      display: inline-block;
      font-size: @font-size-large;
      line-height: 30px;
      float: left;
      @media (max-width: @grid-float-breakpoint-max) {
        font-size: 14px
      }
    }
    > .window-actions{
      float: right;
      display: inline-block;
      > a{
        margin-top: 5px;
        display: inline-block;
      }
    }
  }
  > .window-body{
    clear: both;
    max-height: 200px;
    padding: 15px;
    overflow: auto;
  }
  // Colorize
  &.orange{ .window-variant(@theme-orange); }
  &.red{ .window-variant(@theme-red); }
  &.pink{ .window-variant(@theme-pink); }
  &.yellow{ .window-variant(@theme-yellow); }
  &.blue{ .window-variant(@theme-blue); }
  &.sky{ .window-variant(@theme-sky); }
  &.green{ .window-variant(@theme-green); }
  &.violet{ .window-variant(@theme-violet); }
  &.inverse{ .window-variant(#233); }
  // Window-fill
  &.window-fill{
    border-bottom: 3px solid darken(#e6e6e6, 5%);
    > .window-title{
      background-color: #e6e6e6;
      border-bottom: 0;
      color: #333;
      > .window-actions{
        > a{
          color: #333;
        }
      }
    }
    > .window-body{
      background-color: #e6e6e6;
      color: #333;
    }
    &.orange{ .window-fill-variant(@theme-orange); }
    &.red{ .window-fill-variant(@theme-red); }
    &.pink{ .window-fill-variant(@theme-pink); }
    &.yellow{ .window-fill-variant(@theme-yellow); }
    &.blue{ .window-fill-variant(@theme-blue); }
    &.sky{ .window-fill-variant(@theme-sky); }
    &.green{ .window-fill-variant(@theme-green); }
    &.violet{ .window-fill-variant(@theme-violet); }
    &.inverse{ .window-fill-variant(#233); }
  }
}