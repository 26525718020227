.dropdown-menu{
  padding: 0;
  > li{
    > a{
      padding: 5px 20px;
      font-size: 90%;
      outline: 0;
      &:focus,
      &:active{
        outline: 0;
      }
    }
  }
  &.orange{
    border: 2px solid @theme-orange;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-orange;
      }
    }
  }
  &.sky{
    border: 2px solid @theme-sky;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-sky;
      }
    }
  }
  &.inverse{
    border: 2px solid @theme-inverse;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-inverse;
      }
    }
  }
  &.red{
    border: 2px solid @theme-red;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-red;
      }
    }
  }
  &.pink{
    border: 2px solid @theme-pink;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-pink;
      }
    }
  }
  &.yellow{
    border: 2px solid darken(@theme-yellow, 10%);
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: darken(@theme-yellow, 10%);
      }
    }
  }
  &.blue{
    border: 2px solid @theme-blue;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-blue;
      }
    }
  }
  &.green{
    border: 2px solid @theme-green;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-green;
      }
    }
  }
  &.violet{
    border: 2px solid @theme-violet;
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @component-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @theme-violet;
      }
    }
  }
}