.breadcrumb-icon {
  list-style: none;
  padding: 0;
  display: inline-block;
  .icon{
    font-size: 0.9em;
    margin-right: 3px;
    top: -1px;
    position: relative;
  }
  li{
    float: left;
    a{
      background-color: @theme-inverse;
      color: #FFF;
      display: block;
      text-decoration: none;
      position: relative;
      height: 30px; 
      line-height: 30px;
      padding: 0 15px 0 6px;
      text-align: center;
      margin-right: 20px;
      @media (max-width: @grid-float-breakpoint-max) {
        padding: 0 5px;
      }
    }
    a{
      &:hover{
        background-color: lighten(@theme-inverse, 5%);
        &:before{
          border-top-color: lighten(@theme-inverse, 5%);
          border-right-color: lighten(@theme-inverse, 5%);
          border-bottom-color: lighten(@theme-inverse, 5%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-inverse, 5%);
        }
      }
      &:before,
      &:after{
        content: "";
        position:absolute;
        top: 0;
        border:0 solid @theme-inverse;
        border-width: 15px 15px;
        width: 0;
        height: 0;
      }
      &:before{
        left: -17px;
        border-left-color: transparent;
      }
      &:after{
        left: 100%;
        border-color: transparent;
        border-left-color: @theme-inverse;
      }
    }
    &:first-child{
      a{
        padding-left: 15px;
        -webkit-border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        border-radius: 4px 0 0 4px;
        @media (max-width: @grid-float-breakpoint-max) {
          padding: 0 5px !important;
        }
        &:before{
          border: none;
        }
      }
    }
    &:last-child{
      a{
        padding-left: 10px;
        padding-right: 20px;
        -webkit-border-radius: 0 4px 4px 0;
        -moz-border-radius: 0 4px 4px 0;
        border-radius: 0 4px 4px 0;
        margin-right: 0;
        @media (max-width: @grid-float-breakpoint-max) {
          padding: 0 15px 0 5px !important;
        }
        &:after{
          border:none;
        }
      }
    }
    &.active > a{
      background-color: lighten(@theme-inverse, 15%);
      pointer-events: none;
      &:before{
        border-top-color: lighten(@theme-inverse, 15%);
        border-right-color: lighten(@theme-inverse, 15%);
        border-bottom-color: lighten(@theme-inverse, 15%);
        border-left-color: transparent;
      }
      &:after{
        border-left-color: lighten(@theme-inverse, 15%);
      }
    }
  }
  // white
  &.white{
    li{
      a{
        background-color: @gray-lighter;
        color: #999;
      }
      a{
        &:hover{
          background-color: darken(@gray-lighter, 2%);
          &:before{
            border-top-color: darken(@gray-lighter, 2%);
            border-right-color: darken(@gray-lighter, 2%);
            border-bottom-color: darken(@gray-lighter, 2%);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: darken(@gray-lighter, 2%);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @gray-lighter;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @gray-lighter;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@gray-lighter, 2%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@gray-lighter, 2%);
          border-right-color: lighten(@gray-lighter, 2%);
          border-bottom-color: lighten(@gray-lighter, 2%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@gray-lighter, 2%);
        }
      }
    }
  }
  // orange
  &.orange{
    li{
      a{
        background-color: @theme-orange;
      }
      a{
        &:hover{
          background-color: spin(@theme-orange, -10);
          &:before{
            border-top-color: spin(@theme-orange, -10);
            border-right-color: spin(@theme-orange, -10);
            border-bottom-color: spin(@theme-orange, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-orange, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-orange;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-orange;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-orange, 10%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-orange, 10%);
          border-right-color: lighten(@theme-orange, 10%);
          border-bottom-color: lighten(@theme-orange, 10%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-orange, 10%);
        }
      }
    }
  }
  // red
  &.red{
    li{
      a{
        background-color: @theme-red;
      }
      a{
        &:hover{
          background-color: spin(@theme-red, -10);
          &:before{
            border-top-color: spin(@theme-red, -10);
            border-right-color: spin(@theme-red, -10);
            border-bottom-color: spin(@theme-red, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-red, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-red;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-red;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-red, 15%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-red, 15%);
          border-right-color: lighten(@theme-red, 15%);
          border-bottom-color: lighten(@theme-red, 15%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-red, 15%);
        }
      }
    }
  }
  // pink
  &.pink{
    li{
      a{
        background-color: @theme-pink;
      }
      a{
        &:hover{
          background-color: spin(@theme-pink, -10);
          &:before{
            border-top-color: spin(@theme-pink, -10);
            border-right-color: spin(@theme-pink, -10);
            border-bottom-color: spin(@theme-pink, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-pink, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-pink;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-pink;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-pink, 10%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-pink, 10%);
          border-right-color: lighten(@theme-pink, 10%);
          border-bottom-color: lighten(@theme-pink, 10%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-pink, 10%);
        }
      }
    }
  }
  // yellow
  &.yellow{
    li{
      a{
        background-color: darken(@theme-yellow, 10%);
      }
      a{
        &:hover{
          background-color: spin(@theme-yellow, -10);
          &:before{
            border-top-color: spin(@theme-yellow, -10);
            border-right-color: spin(@theme-yellow, -10);
            border-bottom-color: spin(@theme-yellow, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-yellow, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid darken(@theme-yellow, 10%);
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: darken(@theme-yellow, 10%);
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-yellow, 3%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-yellow, 3%);
          border-right-color: lighten(@theme-yellow, 3%);
          border-bottom-color: lighten(@theme-yellow, 3%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-yellow, 3%);
        }
      }
    }
  }
  // blue
  &.blue{
    li{
      a{
        background-color: @theme-blue;
      }
      a{
        &:hover{
          background-color: spin(@theme-blue, -10);
          &:before{
            border-top-color: spin(@theme-blue, -10);
            border-right-color: spin(@theme-blue, -10);
            border-bottom-color: spin(@theme-blue, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-blue, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-blue;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-blue;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-blue, 10%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-blue, 10%);
          border-right-color: lighten(@theme-blue, 10%);
          border-bottom-color: lighten(@theme-blue, 10%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-blue, 10%);
        }
      }
    }
  }
  // sky
  &.sky{
    li{
      a{
        background-color: @theme-sky;
      }
      a{
        &:hover{
          background-color: spin(@theme-sky, -10);
          &:before{
            border-top-color: spin(@theme-sky, -10);
            border-right-color: spin(@theme-sky, -10);
            border-bottom-color: spin(@theme-sky, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-sky, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-sky;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-sky;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-sky, 3%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-sky, 3%);
          border-right-color: lighten(@theme-sky, 3%);
          border-bottom-color: lighten(@theme-sky, 3%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-sky, 3%);
        }
      }
    }
  }
  // green
  &.green{
    li{
      a{
        background-color: @theme-green;
      }
      a{
        &:hover{
          background-color: spin(@theme-green, -10);
          &:before{
            border-top-color: spin(@theme-green, -10);
            border-right-color: spin(@theme-green, -10);
            border-bottom-color: spin(@theme-green, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-green, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-green;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-green;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-green, 5%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-green, 5%);
          border-right-color: lighten(@theme-green, 5%);
          border-bottom-color: lighten(@theme-green, 5%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-green, 5%);
        }
      }
    }
  }
  // violet
  &.violet{
    li{
      a{
        background-color: @theme-violet;
      }
      a{
        &:hover{
          background-color: spin(@theme-violet, -10);
          &:before{
            border-top-color: spin(@theme-violet, -10);
            border-right-color: spin(@theme-violet, -10);
            border-bottom-color: spin(@theme-violet, -10);
            border-left-color: transparent;
          }
          &:after{
            border-left-color: spin(@theme-violet, -10);
          }
        }
        &:before,
        &:after{
          content: "";
          position:absolute;
          top: 0;
          border:0 solid @theme-violet;
          border-width: 15px 15px;
          width: 0;
          height: 0;
        }
        &:before{
          left: -17px;
          border-left-color: transparent;
        }
        &:after{
          left: 100%;
          border-color: transparent;
          border-left-color: @theme-violet;
        }
      }
      &:first-child{
        a{
          padding-left: 15px;
          -webkit-border-radius: 4px 0 0 4px;
          -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
          &:before{
            border: none;
          }
        }
      }
      &:last-child{
        a{
          padding-left: 10px;
          padding-right: 20px;
          -webkit-border-radius: 0 4px 4px 0;
          -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
          margin-right: 0;
          &:after{
            border:none;
          }
        }
      }
      &.active > a{
        background-color: lighten(@theme-violet, 10%);
        pointer-events: none;
        &:before{
          border-top-color: lighten(@theme-violet, 10%);
          border-right-color: lighten(@theme-violet, 10%);
          border-bottom-color: lighten(@theme-violet, 10%);
          border-left-color: transparent;
        }
        &:after{
          border-left-color: lighten(@theme-violet, 10%);
        }
      }
    }
  }
}