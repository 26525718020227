.btn{
  outline: 0 !important;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
}

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-base);
}
.btn-xs {
  .button-size( (@padding-xs-vertical + 1) ; @padding-xs-horizontal; @font-size-ex-small; @line-height-small; @border-radius-base);
}

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
  border-width: 2px 2px 2px 2px;
}

.btn-inverse{
  .button-variant(@btn-inverse-color; @btn-inverse-bg; @btn-inverse-border);
}
.btn-orange{
  .button-variant(@btn-orange-color, @btn-orange-bg, @btn-orange-border);
}
.btn-red{
  .button-variant(@btn-red-color, @btn-red-bg, @btn-red-border);
}
.btn-pink{
  .button-variant(@btn-pink-color, @btn-pink-bg, @btn-pink-border);
}
.btn-yellow{
  .button-variant(@btn-yellow-color, @btn-yellow-bg, @btn-yellow-border);
}
.btn-blue{
  .button-variant(@btn-blue-color, @btn-blue-bg, @btn-blue-border);
}
.btn-sky{
  .button-variant(@btn-sky-color, @btn-sky-bg, @btn-sky-border);
}
.btn-green{
  .button-variant(@btn-green-color, @btn-green-bg, @btn-green-border);
}
.btn-violet{
  .button-variant(@btn-violet-color, @btn-violet-bg, @btn-violet-border);
}

.btn-border{
  &.btn-default {
    .border-button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
  }
  &.btn-primary {
    .border-button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
  }
  &.btn-success {
    .border-button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
  }
  &.btn-info {
    .border-button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
  }
  &.btn-warning {
    .border-button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
  }
  &.btn-danger {
    .border-button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
  }
  &.btn-inverse {
    .border-button-variant(@btn-inverse-color; @btn-inverse-bg; @btn-inverse-border);
  }
  &.btn-orange {
    .border-button-variant(@btn-orange-color; @btn-orange-bg; @btn-orange-border);
  }
  &.btn-red {
    .border-button-variant(@btn-red-color; @btn-red-bg; @btn-red-border);
  }
  &.btn-pink {
    .border-button-variant(@btn-pink-color; @btn-pink-bg; @btn-pink-border);
  }
  &.btn-yellow {
    .border-button-variant(@btn-yellow-color; @btn-yellow-bg; @btn-yellow-border);
  }
  &.btn-blue {
    .border-button-variant(@btn-blue-color; @btn-blue-bg; @btn-blue-border);
  }
  &.btn-sky {
    .border-button-variant(@btn-sky-color; @btn-sky-bg; @btn-sky-border);
  }
  &.btn-green {
    .border-button-variant(@btn-green-color; @btn-green-bg; @btn-green-border);
  }
  &.btn-violet {
    .border-button-variant(@btn-violet-color; @btn-violet-bg; @btn-violet-border);
  }
}

.btn-round{
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
}

.btn-round-left{
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
}

.btn-round-right{
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
}

.btn-say-love{
  color: @btn-default-color;
  background-color: @btn-default-bg;
  border-color: lighten(@btn-default-color, 40%);
  border-width: 2px 2px 3px 2px;
  &:hover{
    border-color: @btn-default-color;
  }
  &:active,
  &.active,
  &:focus{
    background-color: @theme-pink;
    color: #ffffff;
    border-color: darken(@theme-pink, 10%);
  }
}

.btn-say-like{
  color: @btn-default-color;
  background-color: @btn-default-bg;
  border-color: lighten(@btn-default-color, 40%);
  border-width: 2px 2px 3px 2px;
  &:hover{
    border-color: @btn-default-color;
  }
  &:active,
  &.active,
  &:focus{
    background-color: @theme-blue;
    color: #ffffff;
    border-color: darken(@theme-blue, 10%);
  }
}

.btn-say-vote{
  color: @btn-default-color;
  background-color: @btn-default-bg;
  border-color: lighten(@btn-default-color, 40%);
  border-width: 2px 2px 3px 2px;
  &:hover{
    border-color: @btn-default-color;
  }
  &:active,
  &.active,
  &:focus{
    background-color: @theme-green;
    color: #ffffff;
    border-color: darken(@theme-green, 10%);
  }
}

.btn-social{
  .button-size((@padding-base-vertical / 1.5); (@padding-base-horizontal * 1.5); @font-size-base; @line-height-base; @border-radius-large);
  color: #FFFFFF;
  border-width: 2px 2px 3px 2px;
  &.facebook{
    background-color: darken(@theme-blue, 3%);
    border-color: darken(@theme-blue, 10%);
  }
  &.twitter{
    background-color: @brand-info;
    border-color: darken(@brand-info, 10%);
  }
  &.gplus{
    background-color: desaturate(fade(@theme-red, 90%), 10%);
    border-color: darken(@theme-red, 5%);
  }
  &.instagram{
    background-color: desaturate(fade(@theme-blue, 90%), 10%);
    border-color: darken(@theme-blue, 5%);
  }
  &.tumblr{
    background-color: desaturate(fade(@brand-primary, 90%), 25%);
    border-color: darken(@brand-primary, 5%);
  }
  &.youtube{
    background-color: desaturate(@theme-red, 2%);
    border-color: darken(@theme-red, 10%);
  }
  &:hover{
    color: #ffffff;
    .box-shadow(inset 0 0px 5px rgba(0,0,0,0.125));
  }
  &:active,
  &.active,
  &:focus{
    color: #ffffff;
    margin-top: 1px;
    border-width: 2px 2px 2px 2px;
    .box-shadow(inset 0 0px 5px rgba(0,0,0,0.125));
  }
  i{ font-size: 80%; margin-right: 5px; position: relative; top: -1px; font-weight: bold; }
}

.btn-toolbar{
  a{
    font-size: 90%;
  }
}