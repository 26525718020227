.modal-content {
	border: 2px solid @modal-content-fallback-border-color;
	.box-shadow(0 5px 7px -5px rgba(0, 0, 0, 1));
}

.modal-header {
  padding: @modal-title-padding;
  border-bottom: 1px solid @modal-header-border-color;
  min-height: (@modal-title-padding + @modal-title-line-height);
  background-color: @popover-bg;
  color: #ffffff;
}
// Close icon
.modal-header .close {
  margin-top: -2px;
  color: rgba(255,255,255,0.5);
}

.modal {
	&.scale-effect{
	  .modal-dialog{
	    -webkit-transform: scale(0.8);
	    -moz-transform: scale(0.8);
	    -o-transform: scale(0.8);
	    -ms-transform: scale(0.8);
	    transform: scale(0.8);
	    .transition-transform(~"0.3s ease-out");
	  }
	  &.in {
	    .modal-dialog { 
	      -webkit-transform: scale(1);
	      -moz-transform: scale(1);
	      -o-transform: scale(1);
	      -ms-transform: scale(1);
	      transform: scale(1);
	    };
	  }
	} 
	// super scale
	&.super-effect{
	  .modal-dialog{
	    -webkit-transform: scale3d(1.3, 1.3, 1.3);
	    -moz-transform: scale3d(1.3, 1.3, 1.3);
	    -o-transform: scale3d(1.3, 1.3, 1.3);
	    -ms-transform: scale3d(1.3, 1.3, 1.3);
	    transform: scale3d(1.3, 1.3, 1.3);
	    .transition-transform(~"0.2s ease-out");
	  }
	  &.in {
	    .modal-dialog { 
	      -webkit-transform: scale3d(1, 1, 1);
	      -moz-transform: scale3d(1, 1, 1);
	      -o-transform: scale3d(1, 1, 1);
	      -ms-transform: scale3d(1, 1, 1);
	      transform: scale3d(1, 1, 1);
	    };
	  }
	}
	// bottom in
	&.bottomin-effect{
	  .modal-dialog{
	    .translate(0, 10%);
	    .transition-transform(~"0.3s ease-out");
	  }
	  &.in {
	    .modal-dialog { 
	      .translate(0, 0%);
	    };
	  }
	}
	// flip
	&.flip-effect{
	  .modal-dialog{
	    -webkit-transform: perspective(900px) rotateY(-30deg);
	    -moz-transform: perspective(900px) rotateY(-30deg);
	    -o-transform: perspective(900px) rotateY(-30deg);
	    -ms-transform: perspective(900px) rotateY(-30deg);
	    transform: perspective(900px) rotateY(-30deg);
	    .transition-transform(~"0.3s ease-out");
	  }
	  &.in {
	    .modal-dialog { 
	      -webkit-transform: perspective(900px) rotateY(0deg);
	      -moz-transform: perspective(900px) rotateY(0deg);
	      -o-transform: perspective(900px) rotateY(0deg);
	      -ms-transform: perspective(900px) rotateY(0deg);
	      transform: perspective(900px) rotateY(0deg);
	    };
	  }
	}
	// flip-vertical
	&.flip-vertical-effect{
	  .modal-dialog{
	    -webkit-transform: perspective(900px) rotateX(-30deg);
	    -moz-transform: perspective(900px) rotateX(-30deg);
	    -o-transform: perspective(900px) rotateX(-30deg);
	    -ms-transform: perspective(900px) rotateX(-30deg);
	    transform: perspective(900px) rotateX(-30deg);
	    .transition-transform(~"0.3s ease-out");
	  }
	  &.in {
	    .modal-dialog { 
	      -webkit-transform: perspective(900px) rotateX(0deg);
	      -moz-transform: perspective(900px) rotateX(0deg);
	      -o-transform: perspective(900px) rotateX(0deg);
	      -ms-transform: perspective(900px) rotateX(0deg);
	      transform: perspective(900px) rotateX(0deg);
	    };
	  }
	}
}