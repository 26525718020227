.badge {
	border-bottom: 2px solid darken(@badge-bg, 3%);
  border-right: 2px solid darken(@badge-bg, 3%);
  font-size: @font-size-ex-small;
  border-radius: 4px;
}

.badge {
	&.orange{
	  .badge-variant(@label-orange-bg);
	}
	&.red{
	  .badge-variant(@label-red-bg);
	}
	&.pink{
	  .badge-variant(@label-pink-bg);
	}
	&.yellow{
	  .badge-variant(@label-yellow-bg);
	}
	&.blue{
	  .badge-variant(@label-blue-bg);
	}
	&.sky{
	  .badge-variant(@label-sky-bg);
	}
	&.green{
	  .badge-variant(@label-green-bg);
	}
	&.violet{
	  .badge-variant(@label-violet-bg);
	}
	&.inverse{
	  .badge-variant(@label-inverse-bg);
	}
}