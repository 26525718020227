.progress-bar {
	border-bottom: 1px solid darken(@brand-primary, 5%);
}

.progress-bar{
  &.orange{ .progress-bar-variant(@progress-bar-orange-bg); }
  &.red{ .progress-bar-variant(@progress-bar-red-bg); }
  &.pink{ .progress-bar-variant(@progress-bar-pink-bg); }
  &.yellow{ .progress-bar-variant(@progress-bar-yellow-bg); }
  &.blue{ .progress-bar-variant(@progress-bar-blue-bg); }
  &.sky{ .progress-bar-variant(@progress-bar-sky-bg); }
  &.green{ .progress-bar-variant(@progress-bar-green-bg); }
  &.violet{ .progress-bar-variant(@progress-bar-violet-bg); }
  &.inverse{ .progress-bar-variant(@progress-bar-inverse-bg); }
}