.table-header{
  > thead > tr{
    background-color: @gray-lighter;
  }
}

// Kanda - table-minimal
.table-minimal{
  > tbody > tr > td{
    border: 0px;
  }
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-condensed-cell-padding;
      }
    }
  }
}

// Kanda - table-round
.table-round{
  > thead > tr {
    &:first-child{
      th{
        &:first-child{
          border-top-left-radius: (@border-radius-large * 2);
        }
        &:last-child{
          border-top-right-radius: (@border-radius-large * 2);
        }
      }
    }
  }
  > tbody > tr {
    &:last-child{
      td{
        &:first-child{
          border-bottom-left-radius: (@border-radius-large * 2);
        }
        &:last-child{
          border-bottom-right-radius: (@border-radius-large * 2);
        }
      }
    }
  }
}

// Table-stack
table.table-stack { 
  margin: 0 auto;
  border-collapse: collapse;
  text-rendering: optimizeLegibility;
}

.table-row-variant(fill-orange; @state-orange-bg);
.table-row-variant(fill-red; @state-red-bg);
.table-row-variant(fill-pink; @state-pink-bg);
.table-row-variant(fill-yellow; @state-yellow-bg);
.table-row-variant(fill-blue; @state-blue-bg);
.table-row-variant(fill-sky; @state-sky-bg);
.table-row-variant(fill-green; @state-green-bg);
.table-row-variant(fill-violet; @state-violet-bg);

.table-responsive {
  .table-stack {
    thead {
      display: none;
    }
    tbody td {
      display: block;
    }
    tbody tr td {
      &:first-child{
        background-color: @gray-lighter;
        color: @gray-darker;
      }
    }
    tbody td{
      &:before{
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 10rem;
      }
    }
  }
}