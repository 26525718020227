.popover {
	color: #EEE;
	padding: 0;
	border: 2px solid darken(@popover-bg, 5%);
  border-radius: @border-radius-large;
  &.top     { margin-top: -@popover-arrow-width - 3; }
  &.right   { margin-left: @popover-arrow-width + 3; }
  &.bottom  { margin-top: @popover-arrow-width + 3; }
  &.left    { margin-left: -@popover-arrow-width - 3; }
}

.popover-title{
	font-weight: bold;
  line-height: 18px;
}