// Set main Color
@intro-title:                  6em;
@intro-slogan:                 1.5em;

// Wrapper
.wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  background: @theme-base-color;
}

// Header
.skin-header{
  margin-bottom: 0;
}

// Intro Section
.skin-intro{
  padding-top: 150px;
  color: @theme-base-text;
  @media (max-width: @grid-float-breakpoint-max) {
    padding-top: 100px;
  }
  .skin-title{
    font-size: @intro-title;
    text-transform: uppercase;
    @media (max-width: @grid-float-breakpoint-max) {
      font-size: @intro-title / 2;
    }
  }
  .skin-slogan{
    font-size: @intro-slogan;
    @media (max-width: @grid-float-breakpoint-max) {
      font-size: @intro-slogan / 1.2;
    }
  }
  .skin-explore-button{
    margin: 2em auto 0;
    .explore-button{
      background: @theme-base-text;
      border: 2px solid transparent;
      color: @theme-base-color;
      .transition(all 0.3s ease-in-out);
      &:hover{
        background: @theme-base-color;
        border: 2px solid @theme-base-text;
        color: @theme-base-text;
        .transition(all 0.3s ease-in-out);
        .box-shadow(0 0 3px rgba(0,0,0,0.125));
      }
      &:active{
        .box-shadow(0 0 3px rgba(0,0,0,0.125));
        .box-shadow(inset 0 0 7px rgba(0,0,0,0.2));
      }
    }
  }
}

// Footer Section
.index-skin-footer{
  position: absolute;
  width: 100%;
  bottom: 0;
  .skin-footer-copyright{
    color: #eee;
    a{
      color: #fff;
      font-weight: bold;
      padding: 0 3px;
    }
  }
}

// Extended page
// ------------------------------------

#intro,
#purchase{
  padding: 2em 0;
}


// Page Header
.example-header{
  background: @theme-orange;
  color: @theme-base-text;
  background: url(http://i.imgur.com/N59bziz.jpg) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  .example-title{
    padding: (@line-height-computed * 2) 0;
  }
}

// Mixins
.palette-color(@bgc: transparent){
  background-color: @bgc;
}

// Util
.extended-badge{
  display: inline-block;
  margin: 1em 0;
  padding: 3px 9px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: @theme-base-color;
  color: @theme-base-text;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.example-block{
  padding: 1em;
  margin: 1em auto;
  border: 2px solid #ececec;
  background: #fefefe;
  .box-sizing(border-box);
  &.images-list{
    .img-rounded,
    .img-circle,
    .img-thumbnail,
    .img-effect{
      margin: 10px 5px;
    }
  }
  &.bg-example{
    p{
      padding: 10px;
      margin: 0 0 10px;
      &:last-child{
        margin: 0;
      }
    }
  }
  &.with-bg{
    background: red url(http://i.imgur.com/N59bziz.jpg) no-repeat center bottom;
    border-color: transparent;
  }
  &.with-videobg{
    padding: 0;
    border: 0;
  }
  h4{
    &:not(.media-heading):not(.list-group-item-heading):not(.panel-title):not(.modal-title){
      margin: 20px auto;
    }
  }
  .form-group{
    margin-bottom: 10px;
  }
  .dropdown{
    .example-dropdown{
      margin: 0 5px;
      display: inline-block;
      .dropdown-menu{
        position: static;
        display: block;
        margin-bottom: 5px;      
      }
    }
  }
  .pagination,
  .pager{
    margin: 0 0 5px;
  }
  .navbar-text{
    .navbar-vertical-align(@line-height-computed - 6px);
  }
  > button,
  .btn-group{
    margin-bottom: 5px;
  }
  .lead,
  .small,
  .bootstrap-tagsinput,
  .form-group:last-of-type,
  .navbar,
  .jumbotron,
  .alert:last-child,
  .progress:last-child,
  .list-group:last-child,
  .panel:last-child,
  .well:last-child,
  .panel-group:last-child,
  .window:last-child,
  .media-list,
  blockquote,
  dl,
  pre,
  table,
  p{
    margin-bottom: 0;
  }
}

.scroll-area{
  position: relative;
  height: 250px;
  margin-top: 20px;
  overflow: auto;
}

.icons-block{
  padding: 0;
  list-style: none;
  li{
    .transition(all 0.1s ease-in-out);
    &:hover{
      background: @theme-orange;
      color: #ffffff;
      position: relative;
      .box-shadow(0 0 10px -3px rgba(0, 0, 0, .5));
      .transition(all 0.1s ease-in-out);
    }
    width: 10%;
    min-height: 140px;
    font-size: 75%;
    float: left;
    padding: 10px;
    text-align: center;
    background: #f5f5f5;
    border: 2px solid #FFFFFF;
    .glyphicon,
    .fa{
      font-size: 2.5em;
      display: block;
      margin: 20px auto 5px;
    }
    @media (max-width: @screen-md-max) {
      width: 12.5%;
    }
    @media (max-width: @screen-sm-max) {
      width: 16.666666667%;
    }
    @media (max-width: @screen-xs-max) {
      width: 25%;
      font-size: 70% !important;
    }
  }
  &.awesome-list{
    li{
      min-height: 120px;
      font-size: 80%;
    } 
  }
}

#focusedInput{
  @color-rgba: rgba(red(@input-border-focus), green(@input-border-focus), blue(@input-border-focus), .5);
  border-color: @input-border-focus;
  outline: 0;
  .box-shadow(~"inset 0 0 3px -1px rgba(0,0,0,.2), 0 0 3px @{color-rgba}");
}

#count-example{
  font-size: 1.5em;
}

.going-top{
  padding: 3px 3px 3px 10px;
  background: @gray-lighter;
  border-bottom: 2px solid #ccc;
  color: @gray;
  font-size: 1em;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 1em auto 0;
  font-size: bold;
  float: right;
  .transition(all 0.3s ease-in-out);
  &:hover{
    text-decoration: none;
    background: @gray;
    border-bottom: 2px solid @gray-dark;
    color: @gray-lighter;
    .transition(all 0.3s ease-in-out);
  }
}

.skin-link{
  color: @theme-orange;
  text-decoration: none;
  &:hover{
    color: darken(@theme-orange, 10%);
  }
}

footer#page-footer{
  margin: 2em 0 0;
  background-color: #F7FAFB;
  border-top: 2px solid darken(#F7FAFB, 5%);
  #skin-footer{
    text-align: center;
    padding-top: 4em;
    padding-bottom: 4em;
    > #skin-footer-links{
      font-size: 110%;
      margin: 0 0 2px;
      a,
      strong{
        margin: 0 5px;
      }
      a{
        text-decoration: none;
        color: @theme-orange;
        &:hover{
          color: #666;
        }
        &.active{
          pointer-events: none;
          color: #666;
        }
      }
    }
    > #skin-footer-copyright{
      color: #666;
      margin: 0;
      > a{
        color: #333;        
      }
    }
  }
}

// TOC
#toc{
  margin-top: 3em;
  .toc-listing{
    padding: 0;
    margin: 0;
    list-style: none;
    > li{
      margin: 10px 0;
      > a{
        color: #333;
        text-decoration: none;
        font-weight: bold;
        font-size: 1.1em;
        &:hover{
          color: @theme-orange;
        }
      }
    }
    &.extended{
      > li{
        font-weight: bold;
        color: @theme-orange;
        font-size: 1.1em;
      }
    }
  }
}

// Video example
// ------------------------------------
.video-example{
  width: 100%;
  height: 400px;
}