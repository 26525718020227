.panel {
  border-bottom-width: 2px;
}

.panel-heading {
  padding: 12px 15px;
}

.panel{
  &.orange{ .panel-variant(@panel-orange-border; @panel-orange-text; @panel-orange-heading-bg; @panel-orange-border) }
  &.red{ .panel-variant(@panel-red-border; @panel-red-text; @panel-red-heading-bg; @panel-red-border) }
  &.pink{ .panel-variant(@panel-pink-border; @panel-pink-text; @panel-pink-heading-bg; @panel-pink-border) }
  &.yellow{ .panel-variant(@panel-yellow-border; @panel-yellow-text; @panel-yellow-heading-bg; @panel-yellow-border) }
  &.blue{ .panel-variant(@panel-blue-border; @panel-blue-text; @panel-blue-heading-bg; @panel-blue-border) }
  &.sky{ .panel-variant(@panel-sky-border; @panel-sky-text; @panel-sky-heading-bg; @panel-sky-border) }
  &.green{ .panel-variant(@panel-green-border; @panel-green-text; @panel-green-heading-bg; @panel-green-border) }
  &.violet{ .panel-variant(@panel-violet-border; @panel-violet-text; @panel-violet-heading-bg; @panel-violet-border) }
  &.inverse{ .panel-variant(#333; #FFF; #233; #333) }
}

.panel-invert{
  .panel-invert-variant( @gray-dark; @panel-default-heading-bg );
  &.orange{ .panel-invert-variant(@component-active-color; @theme-orange) }
  &.red{ .panel-invert-variant(@component-active-color; @theme-red) }
  &.pink{ .panel-invert-variant(@component-active-color; @theme-pink) }
  &.yellow{ .panel-invert-variant(@component-active-color; @theme-yellow) }
  &.blue{ .panel-invert-variant(@component-active-color; @theme-blue) }
  &.sky{ .panel-invert-variant(@component-active-color; @theme-sky) }
  &.green{ .panel-invert-variant(@component-active-color; @theme-green) }
  &.violet{ .panel-invert-variant(@component-active-color; @theme-violet) }
  &.violet{ .panel-invert-variant(@component-active-color; @theme-violet) }
  &.inverse{ .panel-invert-variant(@component-active-color; #233) }
}