.badge-variant(@color){
  background-color: @color;
  padding-bottom: 3px;
  padding-right: 6px;
  border-bottom: 2px solid darken(@color, 5%);
  border-right: 2px solid darken(@color, 5%);
}

// KANDA CUSTOM MIXINS
// -------------------------
.list-group-item-custom-variant(@background; @color){
  color: @color;
  background-color: @background;
  border-color: darken(@background, 10%);
  a& {
    color: @color;

    .list-group-item-heading { color: inherit; }

    &:hover,
    &:focus {
      color: @color;
      background-color: darken(@background, 5%);
    }
    &.active,
    &.active:hover,
    &.active:focus {
      color: #fff;
      background-color: lighten(@color, 10%);
      border-color: @color;
    }
  }
}

// Kanda - border button
.border-button-variant(@color; @background; @border){
  color: @background;
  background-color: @color;
  border-color: @background;
  border-width: 2px 2px 3px 2px;
  .transition(all 0.2s ease);

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 5%);
    border-color: darken(@background, 10%);
    border-width: 2px 2px 1px 2px;
    margin-top: 1px;
    .transition(all 0.1s ease);
  }
  &:active,
  &.active,
  .open .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
      border-color: @border;
      color: @color;
      cursor: not-allowed;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// KANDA Custom Mixins
// Nav-Tabs variants ------------------
.nav-bottom-variant(@color){
  border-top: 2px solid darken(@color, 5%);
  > li{
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #FFF;
        background-color: @color;
        border: 2px solid darken(@color, 5%);
        border-top-color: transparent;
        cursor: default;
        -webkit-border-radius: 0 0 2px 2px;
        -moz-border-radius: 0 0 2px 2px;
        border-radius: 0 0 2px 2px;
      }
    }
  }
}
.nav-left-variant(@color){
  border-right: 2px solid darken(@color, 5%);
  > li{
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #FFF;
        background-color: @color;
        border: 2px solid darken(@color, 5%);
        border-right-color: transparent;
        cursor: default;
        -webkit-border-radius: 2px 0 0 2px;
        -moz-border-radius: 2px 0 0 2px;
        border-radius: 2px 0 0 2px;
      }
    }
    a{
      margin-right: 0;
    }
  }
}
.nav-right-variant(@color){
  border-left: 2px solid darken(@color, 5%);
  > li{
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #FFF;
        background-color: @color;
        border: 2px solid darken(@color, 5%);
        border-left-color: transparent;
        cursor: default;
        -webkit-border-radius: 0 2px 2px 0;
        -moz-border-radius: 0 2px 2px 0;
        border-radius: 0 2px 2px 0;
      }
    }
    a{
      margin-left: 0;
    }
  }
}

// Growl variants ---------------------
.growl-variant(@color){
  background-color: @color;
  color: #FFFFFF;
  border-color: darken(@color, 5%);
}

// Window variants --------------------
.window-variant(@color){
  border-color: darken(@color, 5%);
  > a{
    color: #FFF;
  }
  .btn-default{
    color: #333;
  }
  > .window-title{
    background-color: @color;
    border-bottom-color: darken(@color, 5%);
    color: #FFF;
    > .window-actions{
      > a{
        color: #FFF;
      }
    }
  }
}

// Window, Fill variants --------------
.window-fill-variant(@color){
  border-bottom: 3px solid darken(@color, 5%);
  > .window-title{
    background-color: @color;
    color: #FFF;
    > .window-actions{
      > a{
        color: #FFF;
      }
    }
  }
  > .window-body{
    background-color: @color;
    color: #FFF;
  }
}

.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border) {
  border-color: @border;
  border-bottom-width: 3px;
  border-radius: 2px 2px 4px 4px;

  & > .panel-heading {
    color: @heading-text-color;
    background-color: @heading-bg-color;
    border-color: @heading-border;

    + .panel-collapse .panel-body {
      border-top-color: @border;
    }
  }
  & > .panel-footer {
    + .panel-collapse .panel-body {
      border-bottom-color: @border;
    }
  }
}

.panel-invert-variant(@text-color; @background) {
  border-color: darken(@background, 10%);
  border-bottom-width: 3px;
  border-radius: 2px 2px 4px 4px;
  & > .panel-heading {
    color: @text-color;
    background-color: @background;
    border: 0;
    
    + .panel-collapse .panel-body {
      // border-top-color: fade(@background, 70%);
    }
  }
  & > .panel-body{
    background-color: @background;
    color: @text-color;
    border-top: 2px solid darken(@background, 3%);
  }
  & > .panel-footer {
    + .panel-collapse .panel-body {
      // border-bottom-color: @border;
    }
  }
  // Collapse
  > .panel-collapse{
    .panel-body{
      background-color: @background;
      color: @text-color;
      border-top-width: 2px;
    }
  }
}

.text-editor {
  max-height: 200px;
  height: 200px;
  background-color: #fcfcfc;
  border: 2px solid #e6e6e6;
  padding: 10px;
  border-collapse: separate;
  box-sizing: border-box;
  -webkit-box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: scroll;
  outline: none;
}

.masonry-item{
  float: left;
  max-width: 300px;
  margin: 5px;
  &.col1{ width: 140px }
  &.col2{ width: 200px }
  &.col3{ width: 250px }
  img{
    max-width: 100%;
    height: auto;
    display: block;
  }
}