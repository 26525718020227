.nav{
	.open > a {
	  &,
	  &:hover,
	  &:focus {
	    background-color: @nav-link-hover-bg;
	    border-color: transparent;
	  }
	}
}

.nav-tabs {
	&.orange{
	  border-bottom: 2px solid @theme-orange;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-orange;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-orange;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// inverse
	&.inverse{
	  border-bottom: 2px solid @theme-inverse;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      .transition(all 0.1s ease-in-out);
	      color: @theme-inverse;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        .transition(all 0.1s ease-in-out);
	        color: @component-active-color;
	        background-color: @theme-inverse;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// red
	&.red{
	  border-bottom: 2px solid @theme-red;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-red;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-red;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// pink
	&.pink{
	  border-bottom: 2px solid @theme-pink;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-pink;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-pink;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// yellow
	&.yellow{
	  border-bottom: 2px solid @theme-yellow;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-yellow;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: darken(@theme-yellow, 10%);
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// blue
	&.blue{
	  border-bottom: 2px solid @theme-blue;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-blue;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-blue;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// sky
	&.sky{
	  border-bottom: 2px solid @theme-sky;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-sky;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-sky;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// green
	&.green{
	  border-bottom: 2px solid @theme-green;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-green;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-green;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
	// violet
	&.violet{
	  border-bottom: 2px solid @theme-violet;
	  > li {
	    margin-bottom: 0px;
	    > a {
	      color: @theme-violet;
	      border: 0px;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-violet;
	        border: 0px solid @nav-tabs-active-link-hover-border-color;
	      }
	    }
	  }
	}
}

.nav-tabs {
	border-bottom: 2px solid @nav-tabs-border-color;
	> li {
		float: left;
	  // Make the list-items overlay the bottom border
	  margin-bottom: -3px;
	  // Actual tabs (as links)
	  > a {
	  	margin-right: 0px;
	  	line-height: @line-height-base;
	  	border: 2px solid transparent;
			padding: 8px 22px;
	  	border-radius: @border-radius-base @border-radius-base 0 0;
	  	&:hover {
	  		border-color: transparent;
	  	}
	  }
	  // Active state, and its :hover to override normal :hover
	  &.active > a {
	  	&,
	  	&:hover,
	  	&:focus {
	  		color: @nav-tabs-active-link-hover-color;
	  		background-color: @nav-tabs-active-link-hover-bg;
	  		border: 2px solid @nav-tabs-active-link-hover-border-color;
	  		border-bottom-color: transparent;
	  		cursor: default;
	  	}
	  }
	}
	&.nav-justified {
		> li{
			padding: 0;
			border: 0px solid transparent;
			&.active{
				> a{
					&,
					&:hover,
					&:focus{
						border: 2px solid #ddd;
						border-bottom: 2px solid transparent;
					}
				}
			}
		}
	}
}

.tab-content{
	margin-top: 20px;
}

.nav-pills {
	> li{
		> a {
			.transition(all 0.1s ease-in-out);
		}
		+ li {
      margin-left: 2px;
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
      	.transition(all 0.1s ease-in-out);
			}
		}
	}
}

.nav-pills {
	&.inverse{
	  li{
	    > a {
	      color: #333;
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-inverse;
	        border-bottom: 2px solid darken(@theme-inverse, 5%);
	      }
	    }
	  }
	}
	// orange
	&.orange{
	  li{
	    > a {
	      color: darken(@theme-orange, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-orange;
	        border-bottom: 2px solid darken(@theme-orange, 5%);
	      }
	    }
	  }
	}
	// red
	&.red{
	  li{
	    > a {
	      color: darken(@theme-red, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-red;
	        border-bottom: 2px solid darken(@theme-red, 5%);
	      }
	    }
	  }
	}
	// pink
	&.pink{
	  li{
	    > a {
	      color: darken(@theme-pink, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-pink;
	        border-bottom: 2px solid darken(@theme-pink, 5%);
	      }
	    }
	  }
	}
	// yellow
	&.yellow{
	  li{
	    > a {
	      color: darken(@theme-yellow, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: darken(@theme-yellow, 10%);
	        border-bottom: 2px solid darken(@theme-yellow, 5%);
	      }
	    }
	  }
	}
	// blue
	&.blue{
	  li{
	    > a {
	      color: darken(@theme-blue, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-blue;
	        border-bottom: 2px solid darken(@theme-blue, 5%);
	      }
	    }
	  }
	}
	// sky
	&.sky{
	  li{
	    > a {
	      color: darken(@theme-sky, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-sky;
	        border-bottom: 2px solid darken(@theme-sky, 5%);
	      }
	    }
	  }
	}
	// green
	&.green{
	  li{
	    > a {
	      color: darken(@theme-green, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-green;
	        border-bottom: 2px solid darken(@theme-green, 5%);
	      }
	    }
	  }
	}
	// violet
	&.violet{
	  li{
	    > a {
	      color: darken(@theme-violet, 10%);
	    }
	    &.active > a {
	      &,
	      &:hover,
	      &:focus {
	        color: @component-active-color;
	        background-color: @theme-violet;
	        border-bottom: 2px solid darken(@theme-violet, 5%);
	      }
	    }
	  }
	}
}

.tabs-bottom{
  .nav-tabs{
    border-bottom: 0;
    > li{
      + li{
        margin-left: -2px;
      }
      a{
        border-bottom: 0;
      }
      // active state
      &.active > a {
        &,
        &:hover,
        &:focus {
          color: @nav-tabs-active-link-hover-color;
          background-color: @nav-tabs-active-link-hover-bg;
          border: 2px solid @nav-tabs-active-link-hover-border-color;
          border-top-color: transparent;
          cursor: default;
        }
      }
    }
    // KANDA - Custom Colors
    &.orange{ .nav-bottom-variant(@theme-orange); }
    &.red{ .nav-bottom-variant(@theme-red); }
    &.pink{ .nav-bottom-variant(@theme-pink); }
    &.yellow{ .nav-bottom-variant(@theme-yellow); }
    &.blue{ .nav-bottom-variant(@theme-blue); }
    &.sky{ .nav-bottom-variant(@theme-sky); }
    &.green{ .nav-bottom-variant(@theme-green); }
    &.violet{ .nav-bottom-variant(@theme-violet); }
    &.inverse{ .nav-bottom-variant(@theme-inverse); }
  }
  .tab-content{
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.tabs-left{
  .nav-tabs{
    border: 0px;
    border-right: 2px solid #e6e6e6;
    float: left;
    margin-right: 19px;
    > li {
      float: none;
      a{
        min-width: 100px;
        border: 0;
        margin-right: -2px;
        margin-bottom: 0px;
      }
      // active state
      &.active > a {
        &,
        &:hover,
        &:focus {
          color: @nav-tabs-active-link-hover-color;
          background-color: @nav-tabs-active-link-hover-bg;
          border: 2px solid @nav-tabs-active-link-hover-border-color;
          border-right-color: transparent;
          cursor: default;
        }
      }
    }
    // KANDA - Custom Colors
    &.orange{ .nav-left-variant(@theme-orange); }
    &.red{ .nav-left-variant(@theme-red); }
    &.pink{ .nav-left-variant(@theme-pink); }
    &.yellow{ .nav-left-variant(@theme-yellow); }
    &.blue{ .nav-left-variant(@theme-blue); }
    &.sky{ .nav-left-variant(@theme-sky); }
    &.green{ .nav-left-variant(@theme-green); }
    &.violet{ .nav-left-variant(@theme-violet); }
    &.inverse{ .nav-left-variant(@theme-inverse); }
  }
  .tab-content{
    margin: 0;
  }
}

.tabs-right{
  .nav-tabs{
    border: 0px;
    border-left: 2px solid #e6e6e6;
    float: right;
    margin-left: 19px;
    > li {
      float: none;
      a{
        min-width: 100px;
        border: 0;
        margin-left: -2px;
        margin-bottom: 0px;
      }
      // active state
      &.active > a {
        &,
        &:hover,
        &:focus {
          color: @nav-tabs-active-link-hover-color;
          background-color: @nav-tabs-active-link-hover-bg;
          border: 2px solid @nav-tabs-active-link-hover-border-color;
          border-left-color: transparent;
          cursor: default;
        }
      }
    }
    // KANDA - Custom Colors
    &.orange{ .nav-right-variant(@theme-orange); }
    &.red{ .nav-right-variant(@theme-red); }
    &.pink{ .nav-right-variant(@theme-pink); }
    &.yellow{ .nav-right-variant(@theme-yellow); }
    &.blue{ .nav-right-variant(@theme-blue); }
    &.sky{ .nav-right-variant(@theme-sky); }
    &.green{ .nav-right-variant(@theme-green); }
    &.violet{ .nav-right-variant(@theme-violet); }
    &.inverse{ .nav-right-variant(@theme-inverse); }
  }
  .tab-content{
    margin: 0;
  }
}