.pagination{
  &.orange{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-orange;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-orange, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-orange, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-orange, 10);
          background-color: transparent;
          color: @theme-orange;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// red
.pagination{
  &.red{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-red;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-red, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-red, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-red, 10);
          background-color: transparent;
          color: @theme-red;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// pink
.pagination{
  &.pink{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-pink;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-pink, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-pink, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-pink, 10);
          background-color: transparent;
          color: @theme-pink;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// yellow
.pagination{
  &.yellow{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-yellow;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-yellow, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-yellow, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-yellow, 10);
          background-color: transparent;
          color: @theme-yellow;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// blue
.pagination{
  &.blue{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-blue;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-blue, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-blue, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-blue, 10);
          background-color: transparent;
          color: @theme-blue;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// sky
.pagination{
  &.sky{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-sky;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-sky, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-sky, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-sky, 10);
          background-color: transparent;
          color: @theme-sky;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// green
.pagination{
  &.green{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-green;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-green, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-green, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-green, 10);
          background-color: transparent;
          color: @theme-green;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// violet
.pagination{
  &.violet{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: @theme-violet;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(@theme-violet, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(@theme-violet, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(@theme-violet, 10);
          background-color: transparent;
          color: @theme-violet;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
// violet
.pagination{
  &.inverse{
    > li {
      > a,
      > span {
        padding: 0 8px;
        color: @component-active-color;
        background-color: #233;
        border: 2px solid transparent;
        margin-left: 3px;
        border-bottom: 2px solid darken(#233, 10%);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover{
          background-color: darken(#233, 10%);
        }
      }
      &.active{
        > a{
          border: 2px solid spin(#233, 10);
          background-color: transparent;
          color: #233;
        }
      }
      &.disabled {
        > a{
          border: 2px solid transparent;
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}