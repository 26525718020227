// Kanda - ALERT COLORS
.alert{
  &.orange{
    .alert-variant(@alert-orange-bg; @alert-orange-border; @alert-orange-text);
  }
  &.red{
    .alert-variant(@alert-red-bg; @alert-red-border; @alert-red-text);
  }
  &.pink{
    .alert-variant(@alert-pink-bg; @alert-pink-border; @alert-pink-text);
  }
  &.yellow{
    .alert-variant(@alert-yellow-bg; @alert-yellow-border; @alert-yellow-text);
  }
  &.blue{
    .alert-variant(@alert-blue-bg; @alert-blue-border; @alert-blue-text);
  }
  &.sky{
    .alert-variant(@alert-sky-bg; @alert-sky-border; @alert-sky-text);
  }
  &.green{
    .alert-variant(@alert-green-bg; @alert-green-border; @alert-green-text);
  }
  &.violet{
    .alert-variant(@alert-violet-bg; @alert-violet-border; @alert-violet-text);
  }
  &.inverse{
    .alert-variant(@alert-inverse-bg; @alert-inverse-border; @alert-inverse-text);
    button.close{
      color: #eeeeee;
    }
  }
}

.notifications{
  position: relative;
  max-width: 320px;
  padding: 15px 20px 15px 15px;
  &.alert-info{ .growl-variant(@theme-blue); }
  &.alert-success{ .growl-variant(@theme-green); }
  &.alert-warning{ .growl-variant(@theme-orange); }
  &.alert-danger{ .growl-variant(@theme-red); }
  .close {
    position: absolute;
    top: 5px;
    right: 7px;
  }
  .box-shadow(0 3px 8px -3px rgba(0,0,0,0.3));
}