.label {
  padding: .15em .6em .2em;
  font-weight: normal;
  position: relative;
  top: -1px;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.label{
  &.orange{
    .label-variant(@label-orange-bg);
  }
  &.red{
    .label-variant(@label-red-bg);
  }
  &.pink{
    .label-variant(@label-pink-bg);
  }
  &.yellow{
    .label-variant(@label-yellow-bg);
  }
  &.blue{
    .label-variant(@label-blue-bg);
  }
  &.sky{
    .label-variant(@label-sky-bg);
  }
  &.green{
    .label-variant(@label-green-bg);
  }
  &.violet{
    .label-variant(@label-violet-bg);
  }
  &.inverse{
    .label-variant(@label-inverse-bg);
  }
}