.input-group-addon {
  background-color: lighten(@input-group-addon-bg, 2%);
  border: 2px solid @input-group-addon-border-color;
}

.input-group-btn {
  > .btn {
    margin-top: 0 !important;
    position: relative;
    border-width: 2px 1px 2px 2px !important;
    .button-size((@padding-base-vertical + 2); @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
    &:hover,
    &:focus,
    &:active{
      outline: 0;
      margin-top: 0 !important;
      border: 2px solid transparent;
    }
    + .btn {
      margin-left: -1px;
    }
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      margin-right: -1px;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      margin-left: -1px;
    }
  }
}

.input-group{
  .input-group-addon{
    font-size: 1em;
  }
  &.input-group-lg{
    .input-group-addon{
      font-size: 1.2em;
    }
  }
  &.input-group-sm{
    .input-group-addon{
      font-size: 0.8em;
    }
  }
  .form-control + .input-group-btn{
    > .btn{
      border-width: 2px 2px 2px 1px !important;
    }
  }
  .form-control{
    &:focus{
      box-shadow: none;
    }
  }
}
