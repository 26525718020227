@gray-darkest:           #000;
@gray-darker:            lighten(#000, 13.5%); // #222
@gray-dark:              lighten(#000, 20%);   // #333
@gray:                   lighten(#000, 33.5%); // #555
@gray-light:             lighten(#000, 60%);   // #999
@gray-lighter:           lighten(#000, 93.5%); // #eee
@gray-lightest:          lighten(#000, 95.5%); // #f4f4f4

@brand-primary:         #3071a9;
@brand-success:         #449d44;
@brand-info:            #31b0d5;
@brand-warning:         #eea236;
@brand-danger:          #d43f3a;

@theme-base-color:      #DE883E;
@theme-base-text:       #FFFFFF;
@theme-base-gray:       #E9EAED;

// Color Code
@theme-orange:          @theme-base-color;
@theme-red:             #da282f;
@theme-pink:            #F56991;
@theme-yellow:          #e7c850;
@theme-blue:            #4c86bf;
@theme-sky:             #16a09c;
@theme-green:           #67917A;
@theme-violet:          #a0168e;
@theme-inverse:         #555;

@font-family-sans-serif:  'Noto Sans', sans-serif;
@font-family-serif:       @font-family-sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          14px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.75)); // ~11px
@font-size-ex-small:      ceil((@font-size-base * 0.7)); // ~10px

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px
@font-size-giant:         floor((@font-size-base * 5)); // ~70px

@dropdown-link-hover-bg:         @gray-lightest;
@input-border-focus:             #cecece;

@padding-base-vertical:     5px;
@padding-base-horizontal:   11px;

@padding-large-vertical:    7px;
@padding-large-horizontal:  12px;

@padding-small-vertical:    2px;
@padding-small-horizontal:  9px;

@padding-xs-vertical:       1px;
@padding-xs-horizontal:     7px;

@line-height-large:         1.33;
@line-height-small:         1.5;

@border-radius-base:        2px;
@border-radius-large:       3px;
@border-radius-small:       1px;

@badge-bg:                    @gray-lighter;
@badge-border-radius:         @border-radius-base;
@modal-content-bg:                             #fff;
@modal-content-border-color:                   rgba(0,0,0,.4);
@modal-content-fallback-border-color:          darken(@popover-bg, 5%);
@modal-backdrop-bg:           #000;
@modal-backdrop-opacity:      .5;
@modal-header-border-color:   #e5e5e5;
@modal-footer-border-color:   @modal-header-border-color;
@modal-lg:                    900px;
@modal-md:                    600px;
@modal-sm:                    300px;

@popover-bg:                          #2c4242;
@popover-max-width:                   276px;
@popover-border-color:                rgba(0,0,0,.1);
@popover-fallback-border-color:       #000;
@popover-title-bg:                    darken(@popover-bg, 5%);
@popover-arrow-width:                 8px;
@popover-arrow-color:                 @popover-bg;

@tooltip-bg:                  #233;

@well-bg:                     #fafafa;
@well-border:                 darken(@well-bg, 7%);

@btn-default-color:              @gray;
@btn-default-bg:                 #fefefe;
@btn-default-border:             darken(@btn-default-bg, 10%);

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;

@input-color:                    #666;
@input-border:                   #e6e6e6;

@carousel-control-width:                      12%;
@carousel-control-opacity:                    .3;
@carousel-indicator-border-color:             rgba(255,255,255,0.3);

// Kanda - New adding button
@btn-inverse-color:              #FFFFFF;
@btn-inverse-bg:                 #555;
@btn-inverse-border:             darken(@btn-inverse-bg, 5%);

@btn-orange-color:               #FFFFFF;
@btn-orange-bg:                  @theme-orange;
@btn-orange-border:              darken(@btn-orange-bg, 5%);

@btn-red-color:                  #FFFFFF;
@btn-red-bg:                     @theme-red;
@btn-red-border:                 darken(@btn-red-bg, 5%);

@btn-pink-color:                 #FFFFFF;
@btn-pink-bg:                    @theme-pink;
@btn-pink-border:                darken(@btn-pink-bg, 5%);

@btn-yellow-color:               #FFFFFF;
@btn-yellow-bg:                  @theme-yellow;
@btn-yellow-border:              darken(@btn-yellow-bg, 5%);

@btn-blue-color:                 #FFFFFF;
@btn-blue-bg:                    @theme-blue;
@btn-blue-border:                darken(@btn-blue-bg, 5%);

@btn-sky-color:                  #FFFFFF;
@btn-sky-bg:                     @theme-sky;
@btn-sky-border:                 darken(@btn-sky-bg, 5%);

@btn-green-color:                #FFFFFF;
@btn-green-bg:                   @theme-green;
@btn-green-border:               darken(@btn-green-bg, 5%);

@btn-violet-color:               #FFFFFF;
@btn-violet-bg:                  @theme-violet;
@btn-violet-border:              darken(@btn-violet-bg, 5%);

// Kanda custom state

@state-orange-text:              #FFFFFF;
@state-orange-bg:                lighten(@theme-orange, 30%);
@state-orange-border:            @theme-orange;

@state-red-text:                #FFFFFF;
@state-red-bg:                  lighten(@theme-red, 30%);
@state-red-border:              @theme-red;

@state-pink-text:               #FFFFFF;
@state-pink-bg:                 lighten(@theme-pink, 15%);
@state-pink-border:             @theme-pink;

@state-yellow-text:             #FFFFFF;
@state-yellow-bg:               lighten(@theme-yellow, 30%);
@state-yellow-border:           @theme-yellow;

@state-blue-text:              #FFFFFF;
@state-blue-bg:                lighten(@theme-blue, 30%);
@state-blue-border:            @theme-blue;

@state-sky-text:                #FFFFFF;
@state-sky-bg:                  lighten(@theme-sky, 50%);
@state-sky-border:              @theme-sky;

@state-green-text:               #FFFFFF;
@state-green-bg:                 lighten(@theme-green, 15%);
@state-green-border:             @theme-green;

@state-violet-text:             #FFFFFF;
@state-violet-bg:               lighten(@theme-violet, 60%);
@state-violet-border:           @theme-violet;

// KANDA custom label background
@label-orange-bg:             @theme-orange;
@label-red-bg:                @theme-red;
@label-pink-bg:               @theme-pink;
@label-yellow-bg:             @theme-yellow;
@label-blue-bg:               @theme-blue;
@label-sky-bg:                @theme-sky;
@label-green-bg:              @theme-green;
@label-violet-bg:             @theme-violet;
@label-inverse-bg:            #233;

// KANDA - Alert Colors
// 
// ##

@alert-orange-bg:            @state-orange-bg;
@alert-orange-text:          darken(@state-orange-border, 10%);
@alert-orange-border:        darken(@alert-orange-bg, 15%);

@alert-red-bg:               fade(@state-red-bg, 75%);
@alert-red-text:             darken(@state-red-border, 10%);
@alert-red-border:           darken(@alert-red-bg, 15%);

@alert-pink-bg:              @state-pink-bg;
@alert-pink-text:            darken(@state-pink-border, 10%);
@alert-pink-border:          darken(@alert-pink-bg, 15%);

@alert-yellow-bg:            @state-yellow-bg;
@alert-yellow-text:          darken(@state-yellow-border, 20%);
@alert-yellow-border:        darken(@alert-yellow-bg, 15%);

@alert-blue-bg:              @state-blue-bg;
@alert-blue-text:            darken(@state-blue-border, 10%);
@alert-blue-border:          darken(@alert-blue-bg, 15%);

@alert-sky-bg:               @state-sky-bg;
@alert-sky-text:             darken(@state-sky-border, 10%);
@alert-sky-border:           darken(@alert-sky-bg, 15%);

@alert-green-bg:             @state-green-bg;
@alert-green-text:           darken(@state-green-border, 10%);
@alert-green-border:         darken(@alert-green-bg, 15%);

@alert-violet-bg:            @state-violet-bg;
@alert-violet-text:          darken(@state-violet-border, 20%);
@alert-violet-border:        darken(@alert-violet-bg, 15%);

@alert-inverse-bg:           #233;
@alert-inverse-text:         #FFF;
@alert-inverse-border:       #000;

// KANDA CUSTOM COLORS
// -------------------------
@panel-orange-text:           @component-active-color;
@panel-orange-border:         darken(@theme-orange, 5%);
@panel-orange-heading-bg:     @theme-orange;

@panel-red-text:              @component-active-color;
@panel-red-border:            darken(@theme-red, 5%);
@panel-red-heading-bg:        @theme-red;

@panel-pink-text:             @component-active-color;
@panel-pink-border:           darken(@theme-pink, 5%);
@panel-pink-heading-bg:       @theme-pink;

@panel-yellow-text:           @component-active-color;
@panel-yellow-border:         darken(@theme-yellow, 12%);
@panel-yellow-heading-bg:     darken(@theme-yellow, 10%);

@panel-blue-text:             @component-active-color;
@panel-blue-border:           darken(@theme-blue, 5%);
@panel-blue-heading-bg:       @theme-blue;

@panel-sky-text:              @component-active-color;
@panel-sky-border:            darken(@theme-sky, 5%);
@panel-sky-heading-bg:        @theme-sky;

@panel-green-text:            @component-active-color;
@panel-green-border:          darken(@theme-green, 5%);
@panel-green-heading-bg:      @theme-green;

@panel-violet-text:           @component-active-color;
@panel-violet-border:         darken(@theme-violet, 5%);
@panel-violet-heading-bg:     @theme-violet;

@progress-bar-orange-bg:      @theme-orange;
@progress-bar-red-bg:         @theme-red;
@progress-bar-pink-bg:        @theme-pink;
@progress-bar-yellow-bg:      @theme-yellow;
@progress-bar-blue-bg:        @theme-blue;
@progress-bar-sky-bg:         @theme-sky;
@progress-bar-green-bg:       @theme-green;
@progress-bar-violet-bg:      @theme-violet;
@progress-bar-inverse-bg:     #233;

@navbar-transparent-bg:            transparent;
@navbar-transparent-border:        @navbar-transparent-bg;
@navbar-transparent-color:         @navbar-transparent-bg;

@navbar-transparent-text-color:            #dedede;
@navbar-transparent-link-color:            #FFFFFF;
@navbar-transparent-link-hover-color:      #EEEEEE;
@navbar-transparent-link-hover-bg:         @navbar-transparent-brand-hover-bg;
@navbar-transparent-link-active-bg:        fade(@navbar-transparent-brand-color, 20%);
@navbar-transparent-link-disabled-color:   @navbar-transparent-text-color;
@navbar-transparent-link-active-color:     @navbar-transparent-link-hover-color;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

@navbar-transparent-brand-color:           #FFFFFF;
@navbar-transparent-brand-hover-color:     @navbar-transparent-brand-color;
@navbar-transparent-brand-hover-bg:        fade(@navbar-transparent-brand-color, 10%);

// Navbar toggle
@navbar-default-toggle-hover-bg:           #ddd;
@navbar-default-toggle-icon-bar-bg:        #888;
@navbar-default-toggle-border-color:       #ddd;

@navbar-transparent-toggle-hover-bg:           rgba(0,0,0,0.125);
@navbar-transparent-toggle-icon-bar-bg:        #FFF;
@navbar-transparent-toggle-border-color:       #EEE;

@code-color:                  #c74d25;
@code-bg:                     #fcf2ef;

@kbd-color:                   #333;
@kbd-bg:                      #f7f7f7;
@kbd-border:                  #cccccc;

@pre-bg:                      #F7F7F9;
@pre-color:                   @gray-dark;
@pre-border-color:            #e1e1e8;

@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 8);
@input-height-large:             (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 8);
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 8);

