.thumbnail {
	border: 2px solid @thumbnail-border;
  border-radius: @thumbnail-border-radius;
  position: relative;
  .transition(all .2s ease-in-out);
  a&:hover,
  a&:focus,
  a&.active {
    border-color: @link-color;
  }
}

.thumbnail {
	&.thumbnail-cover{
	  padding: 0;
	}

	// KANDA - CAPTION STYLES
	&.caption-slide{
	  overflow: hidden;
	  padding: 0;
	  @media (max-width: @screen-xs-max) {
	    border: 0;
	  }
	  .caption{
	    background-color: rgba(0,0,0,0.8);
	    position: absolute;
	    bottom: -85px;
	    left: 0;
	    margin: auto;
	    width: 100%;
	    max-height: 85px;
	    overflow: hidden;
	    .box-shadow(inset 0 0 10px 0 rgba(0,0,0,0.3));
	    .transition(all .3s ease-in);
	    @media (max-width: @screen-xs-max) {
	      position: relative;
	      top: initial;
	      bottom: initial;
	      right: initial;
	      left: initial;
	    }
	    h3{
	      margin: 0 0 5px;
	      font-size: 18px;
	      color: #FFF;
	      @media (max-width: @screen-xs-max) {
	        font-size: 12px;
	        margin-bottom: 0;
	      }
	      a{
	        color: #FFF;
	      }
	    }
	    p{
	      display: block;
	      line-height: 15px;
	      font-size: 11px;
	      color: #ccc;
	      @media (max-width: @screen-xs-max) {
	        display: none;
	      }
	    }
	  }
	  &:hover{
	    .caption{
	      bottom: 0;
	      .transition(all 0.3s ease-out);
	    }
	  }
	}

	// KANDA - Caption-overlay
	&.caption-overlay{
	  overflow: hidden;
	  padding: 0;
	  @media (max-width: @screen-xs-max) {
	    border: 0;
	  }
	  .caption{
	    visibility:hidden;
	    opacity: 0; 
	    background-color: rgba(0,0,0,0.8);
	    position: absolute;
	    top: 0;
	    left: 0;
	    margin: auto;
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    .box-shadow(inset 0 0 20px 5px rgba(0,0,0,0.8));
	    .transition(all 0.3s ease);
	    @media (max-width: @screen-xs-max) {
	      position: relative;
	      top: initial;
	      bottom: initial;
	      right: initial;
	      left: initial;
	      visibility: visible;
	      opacity: 1;
	      max-height: 85px;  
	    }
	    h3{
	      padding-top: 20px;
	      margin: 0 auto 15px;
	      font-size: 20px;
	      color: #FFF;
	      width: 90%;
	      text-align: center;
	      @media (max-width: @screen-xs-max) {
	        font-size: 12px;
	        text-align: left;
	        padding: 0;
	        margin: 0 auto;
	        width: 100%;
	        &:hover{
	          text-decoration: underline;
	        }
	      }
	      a{
	        color: #FFF;
	        text-decoration: none;
	      }
	    }
	    p{
	      display: block;
	      line-height: 20px;
	      font-size: 11px;
	      width: 90%;
	      text-align: center;
	      margin: auto;
	      color: #ccc;
	      line-height: 15px;
	      @media (max-width: @screen-xs-max) {
	        display: none;
	        width: 100%;
	        margin: 0 auto;
	        text-align: left;
	        font-size: 11px;
	      }
	    }
	  }
	  &:hover{
	    .caption{
	      visibility:visible;
	      opacity: 1;
	      .transition(all 0.3s ease);
	    }
	  }
	}

	// KANDA - Caption, push-up
	&.caption-push-up{
	  overflow: hidden;
	  padding: 0;
	  @media (max-width: @screen-xs-max) {
	    border: 0;
	  }
	  > img{
	    .translate(0, 0);
	    .transition(all .3s);
	  }
	  .caption{
	    background-color: rgba(0,0,0,0.8);
	    position: absolute;
	    left: 0;
	    margin: auto;
	    width: 100%;
	    height: 85px;
	    overflow: hidden;
	    .translate(0, 85px);
	    .box-shadow(inset 0 0 10px 0 rgba(0,0,0,0.3));
	    .transition(all .35s);
	    @media (max-width: @screen-xs-max) {
	      position: relative;
	      top: initial;
	      bottom: initial;
	      right: initial;
	      left: initial;
	      .translate(0, 0);
	    }
	    h3{
	      margin: 0 0 5px;
	      font-size: 18px;
	      color: #FFF;
	      @media (max-width: @screen-xs-max) {
	        font-size: 12px;
	        margin-bottom: 0;
	      }
	      a{
	        color: #FFF;
	      }
	    }
	    p{
	      display: block;
	      line-height: 15px;
	      font-size: 11px;
	      color: #ccc;
	      @media (max-width: @screen-xs-max) {
	        display: none;
	      }
	    }
	  }
	  &:hover{
	    > img{
	      .translate(0, -85px);
	      .transition(all 0.4s);
	    }
	    .caption{
	      .translate(0, -85px);
	      .transition(all 0.3s);
	    }
	  }
	}

	// KANDA - Caption, scale-in
	&.caption-scale-in{
	  overflow: hidden;
	  padding: 0;
	  @media (max-width: @screen-xs-max) {
	    border: 0;
	  }
	  .caption{
	    background-color: rgba(0,0,0,0.8);
	    position: absolute;
	    top: 0;
	    left: 0;
	    margin: auto;
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    visibility: hidden;
	    opacity: 0;
	    .box-shadow(inset 0 0 10px 0 rgba(0,0,0,0.3));
	    .scale(0.5);
	    .transition(all .3s ease-in);
	    @media (max-width: @screen-xs-max) {
	      position: relative;
	      top: initial;
	      bottom: initial;
	      right: initial;
	      left: initial;
	      .translate(0, 0);
	      visibility: visible;
	      opacity: 1;
	    }
	    h3{
	      padding-top: 20px;
	      margin: 0 auto 15px;
	      font-size: 20px;
	      color: #FFF;
	      width: 90%;
	      text-align: center;
	      @media (max-width: @screen-xs-max) {
	        font-size: 12px;
	        text-align: left;
	        padding: 0;
	        margin: 0 auto;
	        width: 100%;
	        &:hover{
	          text-decoration: underline;
	        }
	      }
	      a{
	        color: #FFF;
	        text-decoration: none;
	      }
	    }
	    p{
	      display: block;
	      line-height: 20px;
	      font-size: 11px;
	      width: 90%;
	      text-align: center;
	      margin: auto;
	      color: #ccc;
	      line-height: 15px;
	      @media (max-width: @screen-xs-max) {
	        display: none;
	        width: 100%;
	        margin: 0 auto;
	        text-align: left;
	        font-size: 11px;
	      }
	    }
	  }
	  &:hover{
	    .caption{
	      .scale(1);
	      opacity: 1;
	      visibility: visible;
	      .transition(all 0.3s ease-out);
	    }
	  }
	}
}