.media-right,
.media > .pull-right {
	margin-left: 10px;
	padding-left: 0;
}

.media-left,
.media > .pull-left {
	margin-right: 10px;
	padding-right: 0;
}