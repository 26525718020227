code {
  padding: 3px 6px;
  font-size: 85%;
  font-weight: bold;
  color: @code-color;
  background-color: @code-bg;
  border-radius: @border-radius-base;
  letter-spacing: 0.3px;
  margin: auto 3px;
}

// User input typically entered via keyboard
kbd {
  padding: 2px 6px;
  font-size: 85%;
  color: @kbd-color;
  background-color: @kbd-bg;
  border-radius: @border-radius-base;
  border: 1px solid @kbd-border;
  border-bottom: 1px solid darken(@kbd-border, 10%);
  font-weight: bold;
  letter-spacing: 0.5px;
  box-shadow: 0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
  kbd {
    padding: 0;
    font-size: 100%;
    box-shadow: none;
  }
}

// Blocks of code
pre {
  display: block;
  padding: ((@line-height-computed - 1) / 2);
  margin: 0 0 (@line-height-computed / 2);
  font-size: (@font-size-base - 1); // 14px to 13px
  line-height: @line-height-base;
  word-break: break-all;
  word-wrap: break-word;
  color: @pre-color;
  background-color: @pre-bg;
  border: 1px solid @pre-border-color;
  border-radius: @border-radius-base;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}