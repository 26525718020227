.bg-default{
  border: 2px solid darken(@btn-default-bg, 10%);
  .bg-variant(darken(@btn-default-bg, 5%));
}
.bg-inverse{
  color: #ffffff;
  .bg-variant(@btn-inverse-bg);
}
.bg-orange{
  color: #ffffff;
  .bg-variant(@btn-orange-bg);
}
.bg-red{
  color: #ffffff;
  .bg-variant(@btn-red-bg);
}
.bg-pink{
  color: #ffffff;
  .bg-variant(@btn-pink-bg);
}
.bg-yellow{
  color: #ffffff;
  .bg-variant(@btn-yellow-bg);
}
.bg-blue{
  color: #ffffff;
  .bg-variant(@btn-blue-bg);
}
.bg-sky{
  color: #ffffff;
  .bg-variant(@btn-sky-bg);
}
.bg-green{
  color: #ffffff;
  .bg-variant(@btn-green-bg);
}
.bg-violet{
  color: #ffffff;
  .bg-variant(@btn-violet-bg);
}

.text-orange {
  .text-emphasis-variant(@theme-orange); 
}
.text-red {
  .text-emphasis-variant(@theme-red); 
}
.text-pink {
  .text-emphasis-variant(@theme-pink); 
}
.text-yellow {
  .text-emphasis-variant(@theme-yellow); 
}
.text-blue {
  .text-emphasis-variant(@theme-blue); 
}
.text-sky {
  .text-emphasis-variant(@theme-sky); 
}
.text-green {
  .text-emphasis-variant(@theme-green); 
}
.text-violet {
  .text-emphasis-variant(@theme-violet); 
}

.minor-header{
  font-size: 90%;
  padding-bottom: @line-height-computed - 15;
  margin: (@line-height-computed * 2) 0 @line-height-computed;
  border-bottom: 2px solid @page-header-border-color;
}

.text-giant{ 
  font-size: @font-size-giant;
  line-height: (@line-height-computed *4);
  @media (max-width: @screen-xs-max) {
    font-size: 45px;
  }
}

.lead{
  line-height: 1.7;
}

.text-remove{
  text-decoration:line-through;
}

// Blockquotes-icon
.blockquote-icon {
  position: relative;
  padding: (@line-height-computed / 2) @line-height-computed @line-height-computed (@line-height-computed * 2) ;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border: 0;

  &:before{
    content: '\201C';
    font-size: (@font-size-base * 3);
    position: absolute;
    left: 10px;
    top: 0;
    margin: 0;
    padding: 0;
    line-height: 1em;
    color: #aaa;
  }

  &:after{
    content: '\201D';
    font-size: (@font-size-base * 3);
    position: absolute;
    right: 10px;
    bottom: 0;
    margin: 0;
    padding: 0;
    line-height: 0.5em;
    color: #aaa;
  }

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660
  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: @line-height-base;
    color: @blockquote-small-color;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

// Blockquotes-icon-left
.blockquote-icon-left {
  position: relative;
  padding: (@line-height-computed / 2) @line-height-computed @line-height-computed (@line-height-computed * 2) ;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border: 0;

  &:before{
    content: '\201C';
    font-size: (@font-size-base * 3);
    position: absolute;
    left: 10px;
    top: 0;
    margin: 0;
    padding: 0;
    line-height: 1em;
    color: #aaa;
  }

  &:after{
    content: '';
  }

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660
  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: @line-height-base;
    color: @blockquote-small-color;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

dl {
  margin-top: 0; // Remove browser default
  margin-bottom: @line-height-computed;
  // Description Lists with Checkmark icon
  &.dl-check{
    dt{
      line-height: (@line-height-computed + 5);
      &:before{
        content: "\221A";
        font-weight: bold;
        margin-right: 10px;
        position: relative;
        top: 1px;
      }
    }
  }
  &.dl-arrow{
    dt{
      line-height: (@line-height-computed + 5);
      &:before{
        content: "\2192";
        font-weight: bold;
        margin-right: 5px;
        position: relative;
        top: 1px;
      }
    }
  }
}
dt,
dd {
  line-height: @line-height-base;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0; // Undo browser default
}