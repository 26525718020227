.navbar-form {
  .btn{
    // margin-bottom: 10px;
    outline: 0;
    padding: 4px 10px;
    @media (max-width: @grid-float-breakpoint-max) {
      margin-top: 10px;
      &:focus{
        margin-top: 10px;
      }
    }
  }
}

.navbar-btn {
  &.btn-xs {
    font-size: 60%;
  }
}

.navbar-text {
  font-size: 90%;
}

.navbar-link{
  color: @gray-base;
  font-weight: bold;
}

.navbar-transparent {
  background-color: @navbar-transparent-bg;
  border-color: @navbar-transparent-border;

  .navbar-brand {
    color: @navbar-transparent-brand-color;
    margin-right: 5px;
    &:hover,
    &:focus {
      color: @navbar-transparent-brand-hover-color;
      background-color: transparent;
    }
  }

  .navbar-text {
    color: @navbar-transparent-text-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-transparent-link-color;

      &:hover,
      &:focus {
        color: @navbar-transparent-link-hover-color;
        background-color: @navbar-transparent-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-transparent-link-color;
        background-color: @navbar-transparent-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background-color: @navbar-default-link-disabled-bg;
        @media (max-width: @screen-xs-max) {
          color: #ccc !important;
          background-color: initial !important;
        }
      }
    }
    > li + li {
      margin-left: 5px;
      @media (max-width: @screen-xs-max) {
        margin-left: 0px;
        margin-top: 5px;
      }
    }
  }

  .navbar-toggle {
    &:hover,
    &:focus {
      background-color: transparent;
    }
    .icon-bar {
      background-color: @navbar-transparent-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: transparent;
    box-shadow: none;
    .form-control{
      border: 2px solid #f5f5f5;
      background-color: transparent;
      .box-shadow(inset 0 0 8px -2px rgba(0,0,0,0.4));
      color: #FFFFFF;
   }
   &.collapsing,
   &.in{
    @media (max-width: @grid-float-breakpoint-max) {
      background: transparent;
      border: 2px solid rgba(0,0,0,0.1);
      .box-shadow(0 0 12px rgba(0,0,0,0.125));
      .navbar-text {
        color: #f5f5f5;
      }
      .navbar-nav {
        > li > a {
          color: #FFFFFF;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;

          &:hover,
          &:focus {
            background-color: rgba(255,255,255,0.05);
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            background-color: rgba(255,255,255,0.2);
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: #aaa;
          }
        }
      }
    }
    &.navbar-collapse-black {
      &.collapsing,
      &.in{
        @media (max-width: @grid-float-breakpoint-max) {
          background: @gray-dark;
          .box-shadow(0 0 12px rgba(0,0,0,0.125));
          .navbar-text {
            color: #EEEEEE;
          }
          .navbar-nav {
            > li > a {
              color: #EEEEEE;

              &:hover,
              &:focus {
                color: #EEEEEE;
                background-color: #555555;
              }
            }
            > .active > a {
              &,
              &:hover,
              &:focus {
                color: #EEEEEE;
                background-color: #555555;
              }
            }
            > .disabled > a {
              &,
              &:hover,
              &:focus {
                color: #EEEEEE;
                background-color: #555555;
              }
            }
          }
        }
      }
    }
  }
}

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-transparent-link-active-bg;
        color: @navbar-transparent-link-active-color;
      }
    }

    > li > .dropdown-menu{
      margin-top: -1px;
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: #FFFFFF;
          background-color: transparent;
          &:hover,
          &:focus {
            background-color: rgba(255,255,255,0.05);
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: #FFF;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: #aaa;
          }
        }
      }
      // Divider
      .dropdown-menu{
        .divider{
          display: none;
        }
      }
    }
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-transparent-link-color;
    padding-left: 3px;
    padding-right: 3px;
    &:hover {
      color: @navbar-transparent-link-hover-color;
      text-decoration: none;
    }
    @media (max-width: @screen-xs-max) {
      color: #FFF;
      &:hover{
        color: #EEE;
      }
    }
  }

}

// KANDA - MEGA Bootstrap menu
// ------------------------------------

.navbar {
  &.mega{
    .nav, .collapse, .dropup, .dropdown {
      position: static;
    }  
    .container {
      position: relative;
    }
    .dropdown-menu {
      left: auto;
    }
    .nav.navbar-right .dropdown-menu {
      left: auto;
      right: 0;
    }
    .mega-content {
      padding: 23px 28px;
    }
    .dropdown.mega-full-width .dropdown-menu {
      left: 0; right: 0;
    }
    &.navbar-inverse{
      .dropdown-menu{
        border: 2px solid lighten(@navbar-inverse-border, 2%);
        margin-top: 1px;
        > li {
          background-color: lighten(@navbar-inverse-bg, 10%);
          border-color: @navbar-inverse-border;
          color: #EEEEEE;
          a{
            color: #FFFFFF;
          }
        } 
      }
    }
  }
}

.navbar-right{
  .navbar-form{
    @media (max-width: @screen-xs-max) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}