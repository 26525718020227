.list-group-item{
  &.orange{ .list-group-item-custom-variant(@alert-orange-bg; @alert-orange-text) };
  &.red{ .list-group-item-custom-variant(@alert-red-bg; @alert-red-text) };
  &.pink{ .list-group-item-custom-variant(@alert-pink-bg; @alert-pink-text) };
  &.yellow{ .list-group-item-custom-variant(@alert-yellow-bg; @alert-yellow-text) };
  &.blue{ .list-group-item-custom-variant(@alert-blue-bg; @alert-blue-text) };
  &.sky{ .list-group-item-custom-variant(@alert-sky-bg; @alert-sky-text) };
  &.green{ .list-group-item-custom-variant(@alert-green-bg; @alert-green-text) };
  &.violet{ .list-group-item-custom-variant(@alert-violet-bg; @alert-violet-text) };
}

.list-group{
  &.borderless{
    .list-group-item{
      border: 2px solid transparent;
    }
  }
}