.pager{
  &.square{
    li{
      > a,
      > span {
        border: 2px solid @pager-border;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
    }
    // orange
    &.orange{
      li{
        > a,
        > span {
          color: @theme-orange;
          border-color: darken(@theme-orange, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-orange;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // red
    &.red{
      li{
        > a,
        > span {
          color: @theme-red;
          border-color: darken(@theme-red, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-red;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // pink
    &.pink{
      li{
        > a,
        > span {
          color: @theme-pink;
          border-color: darken(@theme-pink, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-pink;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // yellow
    &.yellow{
      li{
        > a,
        > span {
          color: darken(@theme-yellow, 5%);
          border-color: darken(@theme-yellow, 10%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: darken(@theme-yellow, 5%);
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // blue
    &.blue{
      li{
        > a,
        > span {
          color: @theme-blue;
          border-color: darken(@theme-blue, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-blue;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // sky
    &.sky{
      li{
        > a,
        > span {
          color: @theme-sky;
          border-color: darken(@theme-sky, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-sky;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // green
    &.green{
      li{
        > a,
        > span {
          color: @theme-green;
          border-color: darken(@theme-green, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-green;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // violet
    &.violet{
      li{
        > a,
        > span {
          color: @theme-violet;
          border-color: darken(@theme-violet, 5%);
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: @theme-violet;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
    // inverse
    &.inverse{
      li{
        > a,
        > span {
          color: #233;
          border-color: #233;
          background: #FFFFFF;
          border-width: 2px 2px 3px 2px;
          .transition(all 0.1s ease-in-out);
          &:hover,
          &:focus{
            background-color: #233;
            color: #FFFFFF;
            .transition(all 0.1s ease-in-out);
          }
        }
      }
    }
  }
  &.borderless{
    li{
      > a,
      > span {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 0;
      }
    }
  }
}