.color-palette{
  padding: 0;
  margin: 1em auto;
  li{
    margin-bottom: 1em;
  }
  .palette{
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    .palette-color();
    &.orange{
      .palette-color(@theme-orange);
    }
    &.red{
      .palette-color(@theme-red);
    }
    &.blue{
      .palette-color(@theme-blue);
    }
    &.sky{
      .palette-color(@theme-sky);
    }
    &.green{
      .palette-color(@theme-green);
    }
    &.violet{
      .palette-color(@theme-violet);
    }
    &.pink{
      .palette-color(@theme-pink);
    }
    &.yellow{
      .palette-color(@theme-yellow);
    }
    // gray palette
    &.gray-darkest{
      .palette-color(@gray-darkest);
    }
    &.gray-darker{
      .palette-color(@gray-darker);
    }
    &.gray-dark{
      .palette-color(@gray-dark);
    }
    &.gray{
      .palette-color(@gray);
    }
    &.gray-light{
      .palette-color(@gray-light);
    }
    &.gray-lighter{
      .palette-color(@gray-lighter);
    }
    &.gray-lightest{
      .palette-color(@gray-lightest);
    }
    // default brand color
    &.primary{
      .palette-color(@brand-primary);
    }
    &.success{
      .palette-color(@brand-success);
    }
    &.info{
      .palette-color(@brand-info);
    }
    &.warning{
      .palette-color(@brand-warning);
    }
    &.danger{
      .palette-color(@brand-danger);
    }
  }
}

.img-square{
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  .opacity(0.9);
  &:hover{
    .opacity(1);
  }
}

.img-effect{
  .opacity(0.9);
  &:hover{
    .opacity(1);
  }
  &.bottom-black{
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.8), inset 0 3px 0 rgba(255,255,255,.5), inset 0 -8px 0 rgba(0,0,0,.6), inset 0 -9px 0 rgba(255,255,255,.3);
    -moz-box-shadow: inset 0 0 2px rgba(0,0,0,.8), inset 0 3px 0 rgba(255,255,255,.5), inset 0 -8px 0 rgba(0,0,0,.6), inset 0 -9px 0 rgba(255,255,255,.3);
    box-shadow: inset 0 0 2px rgba(0,0,0,.8), inset 0 3px 0 rgba(255,255,255,.5), inset 0 -8px 0 rgba(0,0,0,.6), inset 0 -9px 0 rgba(255,255,255,.3);
    border-radius: (@border-radius-large * 4);
  }
  &.top-shine{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.8), inset 0 3px 3px rgba(255,255,255,.5), inset 0 -1px 0 rgba(0,0,0,.4);
    -moz-box-shadow: inset 0 0 1px rgba(0,0,0,.8), inset 0 3px 3px rgba(255,255,255,.5), inset 0 -1px 0 rgba(0,0,0,.4);
    box-shadow: inset 0 0 1px rgba(0,0,0,.8), inset 0 3px 3px rgba(255,255,255,.5), inset 0 -1px 0 rgba(0,0,0,.4);
    border-radius: (@border-radius-large * 2);
  }
  &.on-press{
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.2), inset 0 4px 5px rgba(0,0,0,.6), inset 0 1px 0 rgba(0,0,0,.6);
    -moz-box-shadow: 0 1px 0 rgba(255,255,255,.2), inset 0 4px 5px rgba(0,0,0,.6), inset 0 1px 0 rgba(0,0,0,.6);
    box-shadow: 0 1px 0 rgba(255,255,255,.2), inset 0 4px 5px rgba(0,0,0,.6), inset 0 1px 0 rgba(0,0,0,.6);
    border-radius: (@border-radius-large * 2);
  }
  &.gloss{
    -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.5);
    -moz-box-shadow: inset 0 -1px 0 rgba(0,0,0,.5);
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.5);
    border-radius: (@border-radius-large * 2);
    &:after{
      position: absolute;
      content: ' ';
      width: 100%;
      height: 50%;
      top: 0;
      left: 0;
      background: -moz-linear-gradient(top, rgba(255,255,255,0.7) 0%, rgba(255,255,255,.1) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.7)), color-stop(100%,rgba(255,255,255,.1)));
      background: linear-gradient(top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,.1) 100%);
    }
    
  }
  &.tape{
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 0 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
    -moz-box-shadow: inset 0 0 3px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 0 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
    box-shadow: inset 0 0 3px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 0 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
    &:after{
      position: absolute;
      content: ' ';
      width: 60px;
      height: 20px;
      top: -8px;
      left: 50%;
      margin-left: -30px;
      border: solid 1px rgba(137,130,48,.2);
      background: -moz-linear-gradient(top, rgba(230,230,230,.6) 0%, rgba(210,210,210,.6) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,230,230,.6)), color-stop(100%,rgba(210,210,210,.6)));
      background: linear-gradient(top, rgba(230,230,230,.6) 0%,rgba(210,210,210,.6) 100%);
      -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.3), 0 1px 0 rgba(0,0,0,.2);
    }
  }
  &.double-tape{
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 0 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
    -moz-box-shadow: inset 0 0 3px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 0 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
    box-shadow: inset 0 0 3px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 0 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
    &:before{
      position: absolute;
      content: ' ';
      width: 50px;
      height: 15px;
      top: -7px;
      left: 50%;
      margin-left: -25px;
      border: solid 1px rgba(137,130,48,.2);
      background: -moz-linear-gradient(top, rgba(230,230,230,.6) 0%, rgba(210,210,210,.6) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,230,230,.6)), color-stop(100%,rgba(210,210,210,.6)));
      background: linear-gradient(top, rgba(230,230,230,.6) 0%,rgba(210,210,210,.6) 100%);
      -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.3), 0 1px 0 rgba(0,0,0,.2);
    }
    &:after{
      position: absolute;
      content: ' ';
      width: 50px;
      height: 15px;
      bottom: -7px;
      left: 50%;
      margin-left: -25px;
      border: solid 1px rgba(137,130,48,.2);
      background: -moz-linear-gradient(top, rgba(230,230,230,.6) 0%, rgba(210,210,210,.6) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,230,230,.6)), color-stop(100%,rgba(210,210,210,.6)));
      background: linear-gradient(top, rgba(230,230,230,.6) 0%,rgba(210,210,210,.6) 100%);
      -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.3), 0 1px 0 rgba(0,0,0,.2);
    }
  }
}